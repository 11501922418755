import React from 'react'
import '../CSS/Contact.css';
import { useNav } from "../Hooks/useNav"
// import GoogleMaps from '../Map/GoogleMaps';

const Contact = () => {
  const contactRef = useNav("Contact Us")
  return (
    <>
    
    <section ref={contactRef} id='contactSection' className='secondary_section'>
    <div className='contact__container'>
    <h1>Contact</h1>
    <div className='contact__content'>
    <div className="address__content">
    <br/><h2>Address</h2>
    <p>Bijolian, Bhilwara - 311602, Near Teja Ji Ka Chowk</p><br/><br/>
    <h2>Contact Number
    </h2>
    <p>+911489236050<br/>+91 9414576376 <br/>+91 9414576377</p>
    </div>
    <div className="map__content">
    <iframe 
    width="587" 
    height="311"
    id="gmap_canvas"
    title="myFrame"
    src="https://maps.google.com/maps?q=natraj%20hospital%20bijolia&t=&z=13&ie=UTF8&iwloc=&output=embed" 
    scrolling="no">
    </iframe>
    </div>
    </div>
    </div>
    </section>
    </>
  )
}

export default Contact