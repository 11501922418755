import React from 'react'
import { MdHealthAndSafety } from "react-icons/md"
import DepartmentCard from './DepartmentCard';

const CardData=[
    {
        id: 1,
        Dname: "Cardiology",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
    },
    {
        id: 2,
        Dname: "Orthopedics",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
    },
    {
        id: 3,
        Dname: "Surgery",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
    },
    {
        id: 4,
        Dname: "Dentistry",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
    },
    {
      id: 5,
      Dname: "Radiology",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
  },
  {
      id: 6,
      Dname: "Urology",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
  },
  {
      id: 7,
      Dname: "Medicine",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
  },
  {
      id: 8,
      Dname: "Neurology",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
  },
  {
    id: 9,
    Dname: "Paediatrics",
    // desc: "We ensures the best health care as well as clinical service",
    icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
},

]

const DepartmentCardData=() =>
{
  return (
    <div className='cards_container'>
    <div className='cards_details'>
      {
    CardData.map((card) =>(
    <div key= {card.id}>
    <DepartmentCard icons={card.icon} Dname={card.Dname} description={card.desc}/>
    </div> 

    )) 
      }
    
    </div>
    </div>
  );
}

export default DepartmentCardData

