import React from 'react';
import AboutUs from '../Images/about.jpg';
import '../styles/About.css';
import { useNav } from "../Hooks/useNav";

const About = () => {
  
  const aboutRef = useNav("AboutUs")
  return (
    <>
      <section ref={aboutRef} id='aboutusSection'>
        <div className='about__container container-flex'>
        <h1>About Us</h1>
        <div className='inner_content'>
          <div className='text_container'>
            <p><h3>Natraj Hospital, Bijoliyan, Rajasthan:</h3><p color="red"><b> Your Trusted Healthcare Partner

                  Compassionate Care, Exceptional Expertise</b></p>
                  

              </p>
              <br/>
              <p>
                Natraj Hospital in Bijoliyan, Rajasthan, is your beacon of healthcare excellence. 
                With modern facilities and a dedicated team of professionals, </p>
              <br/>
              <p>
                  we offer a comprehensive range of medical services, from primary care to specialized treatments.
                  Our patient-centric approach ensures personalized attention and support at every step of your journey.
                    Experience the Natraj difference and entrust your health to us...</p>
              </div>
              <div className='img_container'>
                <img src={AboutUs} alt='about'></img>
              </div>
        </div>
        </div>
        </section>
    </>
    
  )
}

export default About